.doc_snippet {
  width: 100%;
  outline-color: black;
  border-radius: 1.2rem;
  min-height: 10rem;
  box-sizing: border-box;
  overflow: scroll;
  outline-width: 5px;
  margin-block: 2rem;
  /* border-width: 10px; */
  /* border-color: #e5e5e5; */

  background: #ffffff;
  /* Secondary/Grey/400 */

  border: 3px solid #e9edf7;
  box-sizing: border-box;
  border-radius: 16px;
}
.doc_title {
  color: #707eae;
  font-size: 14px;
  margin-left: 21px;
  margin-right: 5px;
}
.DocmoreButton {
  height: 46px;
  width: 60px;
  margin-right: 6px;
  top: 0px;
  border-radius: 70px;
  background: #ffffff;
  /* Secondary/Grey/600 */

  border: 1px solid #a3aed0;
  box-sizing: border-box;
  border-radius: 70px;
}
.docsite {
  padding-top: 1.25rem;
  margin-bottom: 22px;
  max-width: 60%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.doc_button {
  width: 10rem;
  margin-right: 10px;
  height: 46px;
  border-radius: 70px;
  background-color: white;
  color: #47548c;
  border: 1px solid #a3aed0;
  box-sizing: border-box;
  border-radius: 70px;
  font-family: "DM Sans", sans-serif;
  margin-top: 1.35rem;
  cursor: pointer;
}
.docUrl {
  color: #707eae;
  font-size: 13px;
  margin-left: 82.16px;
}
.history_last {
  text-transform: capitalize;
}
