main {
  color: #11047a;
}

.requests {
  margin-left: 16rem;
  font-family: "DM Sans";
  font-style: normal;
}

.requests-title {
  margin: 1em;
}

.request-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 16px;
  margin: 2em;
  padding: 2em;
  background-color: #fff;
}

.company-info > p {
  display: inline-block;
  color: #707eae;
  margin: 0 0.5em 0.5em 0;
}

.button-container {
  margin-left: auto;
  text-align: center;
}
.button-approve {
  background: #11047a;
  width: 10.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin-right: 12px;
}
.button-approved {
  background: #00826d;
  width: 21.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin-right: 12px;
}
.button-reject {
  width: 10.25em;
  height: 2.875em;
  background-color: #fff;
  color: #a30d27;
  border: 1px solid #a30d27;
  border-radius: 70px;
}

.Processing {
  background: #11047a;
  width: 21.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin: 0 12px 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Approved {
  background: #00826d;
  width: 21.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin: 0 12px 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Rejected {
  background: #a30d27;
  width: 21.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin: 0 12px 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.Error {
  background: #a30d27;
  width: 21.25em;
  height: 2.875em;
  border-radius: 70px;
  color: #fff;
  margin: 0 12px 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
