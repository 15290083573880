.inventory {
  margin-left: 290px;
}
.heading {
  font-family: "DM Sans", sans-serif;
  color: #2b3674;
  margin-left: 30px;
  padding-top: 30px;
}

.top-items {
  display: flex;
  align-items: left;
  justify-content: space-between;
  border-color: white;
  margin-right: 30px;
}
.buttonM {
  height: 46px;
  width: 164px;
  margin-top: 30px;
  background-color: white;
  border-radius: 70px;
  font-size: 20px;
  color: #11047a;
  margin-left: 25px;
  font-family: "DM Sans", sans-serif;
  border-color: white;
  border-style: solid;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
  border-radius: 70px;
}

.buttonA {
  height: 46px;
  width: 164px;
  margin-top: 30px;
  background-color: #11047a;
  border-radius: 20px;
  font-size: 20px;
  color: white;
  margin-left: 25px;
  font-family: "DM Sans", sans-serif;
  border-color: white;
  border-style: solid;
}
.document_heading {
  position: static;
  width: 372px;
  height: 32px;
  left: 0px;
  top: 0px;
  color: #2b3674;

  /* Display Text/Extra-Small/Display Extra-Small (Bold) */

  font-family: "DM Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  margin-top: 25px;
  letter-spacing: -0.02em;
}
.inventory2 {
  background-color: white;
  height: 100%;
  width: 68.125rem;
  border-radius: 20px;
  padding-right: 32px;
  font-family: "DM Sans", sans-serif;
  font-style: bold;
  margin-top: 1rem;
  margin-right: 30px;
  margin-bottom: 3rem;
  padding-left: 1rem;
  padding-top: 20px;
  padding-bottom: 2rem;
}
.inventory2::after {
  display: block;
  clear: both;
}
.dialogLabel {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;
  color: #2b3674;
}
.dialogQues {
  margin-bottom: 10px;
}
.dialogButton {
  height: 46px;
  background: #11047a;
  border-style: none;
  color: white;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: center;

  width: 147px;
  left: 0px;
  top: 650px;
  border-radius: 70px;
}
.Dialoginput {
  height: 46px;
  width: 439px;
  left: 0px;
  top: 0px;
  border-radius: 10px;
  background: #f4f7fe;
  border-style: none;
  padding-left: 10px;

  font-family: "DM Sans", sans-serif;
}

.filters {
  margin-top: 2%;
  margin-bottom: 24px;
  /* border: 2px solid black; */
  /* float: right; */
  /* border: none; */
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  /* position: relative;
  z-index: 5; */
}

.filter-dropdown,
.status-dropdown {
  /* margin-right: 10px; */
  /* padding: 5px; */
  max-width: 400px;
  height: 40px;
  border-radius: 10px;
}
.filter-dropdown option {
  width: 400px;
  border-radius: 4px;
  margin-top: 0.5rem;
}
.clear-filters-button {
  background-color: #f44336; /* Red color */
  color: white;
  border: none;
  border-radius: 10px;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
}

.clear-filters-button:hover {
  background-color: #d32f2f; /* Darker red */
}
.filter-option {
  margin-top: 0.5rem;
}
