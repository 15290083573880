.details {
    margin-left: 3rem;
    margin-right: 2rem;
    margin-top: 2rem;
}

.voilation_content {
    margin-left: 2rem;
    padding-top: 1.5rem;
}

.accordion_heading {
    color: #2B3674;
    font-family: 'DM Sans', sans-serif;
}

.accordion {
    margin-top: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.tags_section {
    padding: 32px;
}

.D_tag {
    font-family: 'DM Sans', sans-serif;
    color: #707eae;
    font-size: 14px;
    margin-right: 45px;

}

.IL {
    padding: 32px;
}
.impact .copy-code-button {
    margin-left: 90%;
    padding: 0.3rem;
    font-size: 12px;
    background-color: #303030;
    color: #b5b5b5;
    border-radius: 5px;
    position: relative;
    top: 55px;
    right: 20px;
    cursor: pointer;
}
.copy-img {
    margin-right: 0.2rem;
    height: 14px;
}
.impact pre{
    overflow-x: scroll;
    background-color: black;
    color: rgb(255, 255, 255);
    padding: 1rem 0.5rem;
    margin: 1rem;
    border-radius: 14px;
}
.impact_container {
    margin-right: 5px;
    padding-top: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
    border-radius: 20px;
    height: 100%;
    margin-top: 4px;
    white-space: pre-line;
    width: 945px;
    background-color: #f4f7fe;
}
.impact_container1{
    min-width: 400px;
    max-height: 300px;
    overflow-y: scroll;
    background-color: #f4f7fe;
    border-radius: 20px;
    padding: 16px;
    margin-right: 16px;
}
.impact_container2 {
    height: 100%;
    width: 80%;
    min-width: 200px; 
    border-radius: 20px;
    padding: 16px;
    margin-right: 16px;
    background-color: #f4f7fe;
    max-height: 300px;
    overflow-y: scroll;
}

.impact_container3 {
    height: 100%;
    width: 50%;
    border-radius: 20px;
    padding: 16px;
    margin-right: 8px;
    background-color: #f4f7fe;
}

.D_elements {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 32px;
    width: 1009px;
}

.D_element {
    margin-right: 45px;
}

.fix {

    width: 39px;
    height: 20px;
    margin-left: 3px;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    color: #707EAE;

}

.impact {
    color: #707EAE;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    white-space: pre-line;

}


.D_ans {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    padding-bottom: 20px;
}

.Dsnippet {
    background-color: white;
    height: 100%;
    border-radius: 1.2rem;
    width: 62.99rem;
    padding-bottom: 1rem;
    padding-left: 32px;
    padding-top: 28px;
    border-style: solid;
    border-color: #E9EDF7;
    margin-bottom: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.Dsnippet2 {
    background-color: white;
    height: 100%;
    border-radius: 1.2rem;
    width: 62.99rem;
    padding-bottom: 1rem;


    border-style: solid;
    border-color: #E9EDF7;
    margin-bottom: 35px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.D_A {}

.D_info {

    padding-left: 32px;

}

.impact_details {

    margin-top: 16px;
}

.url {
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
}

.SS {
    border: 1px solid #E9EDF7;
    box-sizing: border-box;
    border-radius: 16px;
    width: 945px;
    height: 463px;
    flex: 1;
    margin-top: 16px;
    padding: 32px;


}

.description {
    margin-top: 5px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    margin-left: 10px;
    color: #707eae;
    font-weight: 500;
    padding-bottom: 8px;
}

.help_url {
    padding-top: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    margin-left: 10px;
    color: #707eae;
    font-weight: 500;

}

.screenshot {
    width: 946px;
    height: 463px;
    object-fit: cover;
    scale: 1;
    flex: 1;
    top: 0px;
    right: 32px;


}
.jira-card-container{
    padding: 3rem;
    width: 800px;
    justify-items: center;
}
.assign{
    background-color: #11047a;
    margin-left: 0rem;
    margin-top: 1.5rem;
    height: 46px;
    width: 242px;
    color: white;
    border-color: #11047a;
    border-radius: 70px;
    font-family: 'DM Sans', sans-serif;
    margin-left: 70%;
    cursor: pointer;
}
.assign-disabled {
    background-color: #818283;
    cursor: default;
}