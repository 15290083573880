.login-container {
  display: flex;
  color: #2b3674;
  font-family: "DM Sans";
  font-style: normal;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  background-color: #fff;
}

.login-c-main {
  margin: 3rem;
  min-width: 600px;
}
.login-c-main > h2 {
  margin: 1em 1em 1em 0;
}
.login-c-image {
  margin-left: auto;
  height: 100vh;
}
