.members {
  margin-left: 16rem;
  color: #2b3674;
  font-family: "DM Sans", sans-serif;
}
.members-top-item {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  /* margin-right: 22rem; */
}

.members-heading {
  /* font-family: "DM Sans", sans-serif; */
  margin-left: 30px;
  padding-top: 30px;
  margin-bottom: 24px;
}
.members-main-container {
  display: flex;
  margin-left: 30px;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
  flex-wrap: wrap;
  gap: 2rem;
  padding-bottom: 2rem;
}

.members-main-membersList-heading {
  display: flex;
  margin: 1em;
  flex-direction: row;
}
.members-main-membersList-heading > button {
  margin-left: auto;
  width: 10.25em;
  height: 2.875em;
  background: #ffffff;
  color: #47548c;
  /* Secondary/Grey/600 */
  border: 1px solid #a3aed0;
  border-radius: 70px;
}
.members-main-membersList {
  width: 550px;
  height: 350px;
  padding: 1em;
  /* overflow-y: scroll; */
  /* margin-right: 1em; */
  background: #ffffff;
  border-radius: 20px;
  /* max-height: 545px; */
  box-shadow: 2px 2px 2px rgb(101, 101, 101);
}
.members-main-membersList-table {
  width: 100%;
  margin: 1em;
  text-align: left;
  max-height: 400px;
  overflow-y: auto;
}

.members-main-membersList-tr {
  width: 97%;
  /* margin: 0.5em; */
  height: 4em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

td > button {
  width: 40px;
  height: 40px;
  background: #f4f7fe;
  border-radius: 12px;
  border: none;
}
.members-main-membersList-table {
  height: fit-content;
  max-height: 280px;
  overflow-y: scroll;
}