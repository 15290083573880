.reports {
  margin-left: 320px;
  font-family: "DM Sans", sans-serif;
  margin-bottom: 3rem;
  padding-right: 2rem;
}
.heading {
  font-family: "DM Sans", sans-serif;
  color: #2b3674;
  margin-left: 30px;
  padding-top: 30px;
}

.Container {
  margin-right: 2rem;
}
