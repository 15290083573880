.project-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  position: absolute;
  width: 1089px;
  height: 1595px;
  left: 323px;
  top: 40px;
}

.metrix-container {
  /* Frame 64 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  position: absolute;
  width: 1089px;
  height: 1595px;
  left: 323px;
  top: 50px;
  /* margin-top: 100px; */
}
.metrix-heading {
  /* Metrics */

  width: 122px;
  height: 42px;

  /* Display Text/Small/Display Small (Bold) */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 42px;
  /* identical to box height, or 124% */

  letter-spacing: -0.02em;

  /* Secondary/Grey/900 */

  color: #2b3674;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
h1 + p {
  /* Displaying combined metrics for pages available to you. */

  width: 516px;
  height: 32px;

  /* Body Text/Extra-Large/Body Extra-Large (Bold) */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  /* Secondary/Grey/900 */

  color: #2b3674;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
/* .filters {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 24px;
  gap: 10px;

  width: 174px;
  height: 46px;

  background: #ffffff;

  border: 1px solid #a3aed0;
  border-radius: 70px;

  flex: none;
  order: 0;
  flex-grow: 0;
} */
option {
  /* Filter by domain */

  width: 106px;
  height: 24px;

  /* Body Text/Small/Body Medium (Bold) */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Secondary/Grey/800 */

  color: #47548c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.headings {
  /* Pages */

  width: 55px;
  height: 32px;

  /* Body Text/Extra-Large/Body Extra-Large (Bold) */

  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 160% */

  letter-spacing: -0.02em;

  /* Secondary/Grey/900 */

  color: #2b3674;
}
.counts {
  /* Frame 44 */

  box-sizing: border-box;

  position: absolute;
  height: 107px;
  left: 0px;
  right: 0px;
  top: calc(50% - 107px / 2 - 529px);

  /* Secondary/ Primary White */

  background: #ffffff;
  /* Secondary/Grey/400 */

  border: 1px solid #e9edf7;
  border-radius: 16px;
}
.countDetails {
  /* Frame 806 */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  position: absolute;
  width: 281px;
  height: 48px;
  left: 32px;
  top: 32px;
  /* Group 805 */

  width: 281px;
  height: 48px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.details798 {
  position: absolute;
  width: 110px;
  height: 48px;
  left: 180px;
  top: 0px;
  /* Pages with failures */
  font-size: 14px;
  line-height: 28px;

  font-weight: 500;
  /* identical to box height, or 167% */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  letter-spacing: -0.02em;

  /* Secondary/Grey/700 */

  color: #707eae;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.details797 {
  /* Group 797 */

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  letter-spacing: -0.02em;

  /* Secondary/Grey/700 */

  color: #707eae;

  font-size: 14px;
  line-height: 28px;

  position: absolute;
  /* width: 110px; */
  height: 48px;
  left: 0px;
  top: 0px;
}
.hrLine {
  /* Line 29 */

  /* margin-top:150px; */
  width: 1089px;
  height: 0px;

  /* Secondary/Grey/500 (Primary Grey) */

  border: 2px solid #e0e5f2;

  /* Inside auto layout */

  flex: none;
  order: 4;
  flex-grow: 0;
}
.elements {
  box-sizing: border-box;

  position: absolute;
  height: 112px;
  left: 0px;
  right: 0px;
  top: calc(50% - 112px / 2 - 315.5px);

  border-radius: 16px;
}
.elemcounts {
  box-sizing: border-box;

  position: absolute;
  height: 112px;
  left: 0px;
  right: 0px;
  /* top: calc(50% - 112px/2 - 315.5px); */

  /* Secondary/ Primary White */

  background: #ffffff;
  /* Secondary/Grey/400 */

  border: 1px solid #e9edf7;
  border-radius: 16px;
}
