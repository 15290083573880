.accessibility_form{
    height: 100%;
    width: 516px;
    left: 0px;
    top: 0px;
    border-radius: 20px;
    padding: 40px;
    background-color: white;
    margin-left: 31.79px;
    margin-bottom: 57px;
    box-shadow: 0px 18px 40px 0px rgba(112, 144, 176, 0.12);
    

}
.multi{
    margin-top: 10px;
    border-radius: 10px;
   height: 46px;
    background-color: #f4f7fe;
    border-color: white;
    border: none;
}


.input{
    background-color: #F4F7FE;
    height: 46px;
    width: 30rem;
    border-radius: 10px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    border-color: white;
    border-style: solid;  
    margin-right: 2rem;
    color: #707eae;
    padding: 3px;
    margin-top: 0.5rem;
    padding-left: 24px;
}
.form-check{
    color: #707eae;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
    
    margin-top: 0.7rem;
    font-family: DM Sans;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
/* identical to box height, or 143% */

letter-spacing: -0.02em;

/* Secondary/Grey/700 */

color: #707EAE;
}
.question{
    color: #2B3674;
    font-size: 16px;
    font-style: normal;
    margin-top: 26px;
}
.submit{
    background-color: #11047a;
    margin-left: 0rem;
    margin-top: 1.5rem;
    height: 46px;
    width: 242px;
    color: white;
    border-color: #11047a;
    border-radius: 70px;
    font-family: 'DM Sans', sans-serif;
    cursor: pointer;
}
.form_heading{
    position: static;
width: 372px;
height: 32px;
left: 0px;
top: 0px;
color: #2B3674;

/* Display Text/Extra-Small/Display Extra-Small (Bold) */

font-family: 'DM Sans',sans-serif;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

display: flex;
align-items: center;
letter-spacing: -0.02em;
}

.radioButton{
   margin-right:12px;
   margin-top: 10px;
   
}
.form-check-input{
    margin-right: 12px;
    margin-top: 10px;
    
}

.css-6hp17o-MuiList-root-MuiMenu-list{

/* for the project dropdown menu */
max-height: 160px;

}