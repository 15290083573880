.illustration{
    margin-right: 3rem;
   
}
.illus_contain{
    position: static;
    width: 516px;
    height: 791px;
    left: 548px;
    top: 0px;
    
    background: #11047A;
    /* Shadows/Soft Shadow/Soft Shadow - Style 1 */
    
    box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
    border-radius: 20px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0px 32px;
}
.illus_heading{
    color: white;
    font-size: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 4rem;
    margin-bottom: 3rem;
}

.illus_button{
    background-color: white;
    color: #11047A;
    font-weight: 700;
    margin-top: 1.5rem;
    margin-left: 1rem;
    height: 46px;
    border-radius: 1.5rem;
    font-family: 'DM Sans', sans-serif;
    border-color: #11047A;
    padding: 11px 24px;
}

.illus_image{
    
    margin-left: 57rem;
    position: absolute;
width: 358px;
height: 358px;
left: 78.79px;
top: 550px;
margin-top: 1rem;
}
