* {
  padding: 0;
  margin: 0;
}
ul {
  padding: 0%;
  margin: 0%;
}
li {
  list-style: none;
}

body {
  background-color: #f4f7fe;
  margin: 0;
  padding: 0;
}

.sidebar {
  position: fixed;
  background-color: white;
  width: 16.125rem;
  height: 100%;
}
