.entire_report {
    margin-top: 2rem;
    margin-right: 3rem;
    font-family: 'DM Sans', sans-serif;
    margin-bottom: 3rem;
    margin-left: 3rem;
}

.tabs {
    margin-left: 2rem;
    margin-top: 1rem;
}

.ER_Container {
    margin-right: 3rem;
}

.buttonBack {
    background-color: #f4f7fe;
    border: none;
    cursor: pointer;
}