.previous_report {

    background-color: white;
    height: 100%;
    width: 100%;
    border-radius: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: bold;
    margin-top: 1rem;
    margin-bottom: 3rem;
    padding-left: 1rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
}


.searchbar {
    height: 46px;
    width: 439px;
    border-radius: 10px;
    font-size: 14px;
    border-color: white;
    border-style: solid;
    background-color: #F4F7FE;
    background: url("../../../assets/Search\ Icon.svg") no-repeat #F4F7FE;
    background-size: 15px;
    margin-top: 20px;
    padding-left: 45px;
    background-position: 20px;

}

.pr_ti {
    display: flex;

    justify-content: space-between;
}

.buttonDAR {
    width: 213px;
    height: 46px;
    left: 796.21px;
    bottom: 605px;
    font-family: 'DM Sans', sans-serif;
    background: #FFFFFF;
    color: #485585;
    /* Secondary/Grey/600 */
    margin-top: 20px;
    margin-right: 80px;
    border: 1px solid #A3AED0;
    box-sizing: border-box;
    border-radius: 70px;
    align-self: flex-end;
}

.moreButton {
    border: 1px solid #A3AED0;
    box-sizing: border-box;
    border-radius: 70px;
    width: 60px;
    cursor: pointer;
    height: 46px;
    margin-right: 33px;
    background-color: white;

}

.moreButtonTop {

    width: 46px;
    height: 46px;
    background: #F4F7FE;
    margin-left: 12px;
    border-color: #F4F7FE;
    border-style: solid;

    /* Secondary/Grey/300 */

    background: #F4F7FE;
    border-radius: 12px;
}

.accordion-container {
    margin-top: 5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}
.accordion-container{
    border: none;
    box-shadow: none;
}
.css-o4b71y-MuiAccordionSummary-content{
    display: flex;
    width: fit-content !important;
    justify-content: flex-start;
}
.css-o4b71y-MuiAccordionSummary-content{
    display: flex;
    justify-content: flex-start;
}
.MuiSvgIcon-root {
    fill: #11047a; /* Fills the SVG path with the color */
    color: #11047a; /* Applies the color to the entire icon */
  }
  .css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
    position: static;
  }