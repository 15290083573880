.schedule{
    margin-left: 16rem;
    font-family: 'DM Sans', sans-serif;
    
}
.heading{
    font-family: 'DM Sans', sans-serif;
    color: #2B3674;
    margin-left: 30px;
    padding-top: 30px;
    margin-bottom: 24px;
}

.ques{
    font-family: 'DM Sans', sans-serif;
    color: white;
    font-size: 38px;
    padding: 20px;
}
.quesCont{
    height: 154px;
    width: 154px;
}

.button{
    background-color: white;
    height: 45px;
    width: 188px;
    border-radius: 20px;
    font-size: 20px;
    color: #11047a;
    margin-left: 25px;
    font-family: 'DM Sans', sans-serif;
    border-color: white;
}
.top-item{
    display: flex;
    align-items: left;
    justify-content: space-between;
    margin-right: 22rem;
}
.inline{
    display: inline-flex;
    align-items:flex-start;
    justify-content: space-between;

}

.userinputs{
    display: flex;
    align-items: top;
    justify-content: space-between;
    
}

.Logoutbutton{
    background-color: white;
    height: 45px;
    width: 188px;
    border-radius: 20px;
    font-size: 20px;
    color: #11047a;
    margin-left: 25px;
    font-family: 'DM Sans', sans-serif;
    margin-right: 100px;
    
}



