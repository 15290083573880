.scanned_result{
   margin-left: 11.3rem;
   padding-top: 4rem;
   padding-bottom: 4rem;
   margin-right: 2rem;
}
.scanned_result_container{
    background-color: white;
    width: 68rem;
    height: 100%;
    padding-bottom: 2rem;
    margin-right: 2rem;
    margin-top: 2.5rem;
    border-radius: 20px;
    padding-left: 40px;
    margin-bottom: 3rem;
   padding-right: 40px;
}
.SR_heading{
    font-family: 'DM Sans', sans-serif;
    color: #2B3674;
    font-size: 1.5rem;
    padding-left: 2rem;
    margin-top: 3rem;
}
.searchbar_SR{
    height: 46px;
    width: 439px;
    border-radius: 10px;
    font-size: 16px;
    border-color: white;
    border-style: solid;
    background-color: #F4F7FE;
    background: url("../../../../assets/Search\ Icon.svg") no-repeat #F4F7FE;
    background-size: 15px;
    margin-top: 20px;
    padding-left: 45px;
    background-position: 20px;
    margin-left: 30px;
}
.SR_Sep{
    margin-left: 30px;
}
