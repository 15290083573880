.page_wise{
    margin-top: 2rem;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    margin-left: 30px;
    border-color: #e5e5e5;
    border-style: solid;
    font-family: 'DM Sans', sans-serif;
    background: #FFFFFF;
/* Secondary/Grey/400 */

border: 1px solid #E9EDF7;
box-sizing: border-box;
border-radius: 16px;

    
}

.PW_tag{
    display: inline-flex;
    font-size: 14px;
    color: #707EAE;
    font-family: 'DM Sans', sans-serif;
    margin-right: 11rem;
}
.buttonEntire{
    cursor: pointer;
    
    /* Primary/Purple Blue/900 */

background: #11047A;
border-radius: 70px;
border-style: solid;
border-color: #11047A;
/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
border-style: solid;
margin: 12px 0px;
    color: white;
    font-family: 'DM Sans',sans-serif;
    height: 2.875rem;
    width: 11rem;
    
}

.button_link{
    color: white;
}
.button_link2{
    color: #47548C;
}

.buttonPage{
    background: white;
border-radius: 70px;
cursor: pointer;
border-style: solid;
border-width: 1px;
border-color: #707EAE;
/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
border-style: solid;
margin: 12px 0px;
    color: white;
    font-family: 'DM Sans',sans-serif;
    height: 2.875rem;
    width: 11rem;
    
}
