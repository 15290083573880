.dropdown_white{
    height: 46px;
    width: 12.25rem;
    border-radius: 3rem;
    color: #47548C;
    font-family: 'DM Sans',sans-serif;
    margin-left: 6px;
    margin-top: 0.5rem;
    background-color: #FFFFFF;
    padding-left: 24px;
/* Secondary/Grey/600 */
background-image: '../../../../../../assets/Accordion.svg';
background-position: right;

border: 1px solid #A3AED0;
box-sizing: border-box;
border-radius: 70px;
}

.dropdown_purple{
    height: 46px;
    width: 12.25rem;
    border-radius: 3rem;
    color: white;
    font-family: 'DM Sans',sans-serif;
    margin-left: 400px;
    margin-top: 0.5rem;
    background-color: #11047A;
    padding-left: 24px;
}
.accordion{
    margin-top: 2rem;
}
.accordion_heading{
    color: #2B3674;
    margin-right: 8px;
    cursor: pointer;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
}
.dropdown_white_DSI{
    height: 46px;
    width: 256pxrem;
    border-radius: 3rem;
    color: #47548C;
    font-family: 'DM Sans',sans-serif;
    margin-left: 8px;
    margin-top: 0.5rem;
    background: #FFFFFF;
    padding-left: 24px;
/* Secondary/Grey/600 */
padding-right: 10px;
border: 1px solid #A3AED0;
box-sizing: border-box;
border-radius: 70px;
}

