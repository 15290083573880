.teams-main-teamsList {
  padding: 1em;
}
.teams-main-teamsList-heading {
  display: flex;
  margin: 1em;
  flex-direction: row;
}
.teams-main-teamsList-heading > button {
  margin-left: auto;
  width: 10.25em;
  height: 2.875em;
  background: #ffffff;
  color: #47548c;
  /* Secondary/Grey/600 */
  border: 1px solid #a3aed0;
  border-radius: 70px;
}
.teams-main-teamsList {
  width: 550px;
  height: 350px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 2px 2px 2px rgb(101, 101, 101);
}
.teams-main-teamsList-table {
  width: 100%;
  margin: 1em;
  text-align: left;
}

.teams-main-teamsList-tr {
  width: 100%;
  margin: 0.5em;
  height: 4em;
}

td > button {
  width: 40px;
  height: 40px;
  background: #f4f7fe;
  border: none;
  border-radius: 12px;
}
