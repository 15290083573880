.snippet{
    background-color: white;
height: 9.8rem;
border-radius: 1.2rem;
width: 62.99rem;
margin-bottom: 1rem;
border-style: solid;
border-color: #E9EDF7;
display: flex;
align-items: flex-start;
justify-content: space-between;
}
.IS_tag{
    font-family: 'DM Sans',sans-serif;
    color: #707eae;
    font-size: 14px;
    margin-right: 5px;
}
.info_elements{
    margin-left: 2rem;
    
}
.hidden{
    display: none;
}
.info_element{
    margin-left: 2rem;
    margin-top: 1rem;
}
.site_info{
    margin-left: 2rem;
    margin-top: 14px
}
.buttons{
    margin-right: 2rem;
    cursor: pointer;
    
}
.IS_button_white{
    background-color: white;
    border-color: #A3AED0;
    border-radius: 70px;
    height: 46px;
    width: 10rem;
    margin-top: 11px;
    font-family: 'DM Sans',sans-serif;
    color: #47548C;
    border-style: solid;
    cursor: pointer;
}

.IS_button_purple{
    background-color:#11047A;
    border-color: #11047A;
    height: 46px;
    cursor: pointer;
    width: 10rem;
    border-radius: 3rem;
    margin-top: 3.5rem;
    font-family: 'DM Sans',sans-serif;
    color: white;
}