.sidebar {
  height: 850px;
  z-index: 2
}

.logo {
  margin-left: 1rem;
  margin-top: 1rem;
}

.seperator {
  margin-top: 20px;
  background-color: #e0e5f2;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 1.3rem;
}

.item>ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  margin-top: 10px;
  margin-left: 1.3rem;
}

.text {
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;
  margin-left: 1.25rem;
  font-size: 1rem;
  color: #707eae;
  border-bottom: 1px white;
}

.active {
  margin-top: 10px;
  font-family: "DM Sans", sans-serif;
  margin-left: 1.25rem;
  font-size: 1rem;
  color: #707eae;
  border-bottom: 1px white;
}

.item_logout {
  /* Log Out */
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  position: absolute;
  height: 280px;
  left: 10px;
  top: calc(65% - 28px/2 + 0.5px);

  /* Body Text/ Medium/ Medium */

  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  /* identical to box height, or 175% */

  letter-spacing: -0.02em;

  /* Secondary/Grey/600 */

  color: #A3AED0;
}