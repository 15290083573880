.latest_report{
    margin-left: 11rem;
    padding-top: 2rem;
    height: 100%;
    font-family: 'DM Sans', sans-serif;
    
}
.top-latest{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.backButton{
    margin-right: 16px;
}

.latest_heading{
    font-size: 1.25rem;
    margin-left: 0.5rem;
    font-family: 'DM Sans', sans-serif;
    color: #2B3674;
    font-family: DM Sans;
font-style: normal;
font-weight: bold;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

letter-spacing: -0.02em;

/* Secondary/Grey/900 */

color: #2B3674;
}
.buttonL{
    position: static;
width: 232px;
height: 46px;
cursor: pointer;
margin-bottom: 15px;
background: #FFFFFF;
/* Shadows/Soft Shadow/Soft Shadow - Style 1 */

box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
border-radius: 70px;
border-color: white;
border-style: solid;
/* Inside auto layout */

flex: none;
cursor: pointer;
order: 0;
flex-grow: 0;
border-color: white;
margin-right: 11rem;

font-family: 'DM Sans', sans-serif;
}
.chart_card{
    position: static;
width: 341px;
height: 422px;
padding-top: 23px;

/* Secondary/ Primary White */

background: #FFFFFF;
/* Shadows/Soft Shadow/Soft Shadow - Style 1 */

box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
border-radius: 20px;
padding-bottom: 30px;
/* Inside auto layout */
margin-top: 34px;
flex: none;
order: 0;
flex-grow: 0;
margin: 0px 33px;
}
.card{
    display: inline-flex;
    margin-top: 27px;
}

.chart_heading{
    font-family: DM Sans;
    
    font-size: 14px;
    line-height: 28px;
    /* identical to box height, or 200% */
    
    letter-spacing: -0.02em;
    
    /* Secondary/Dark Grey/900 */
    
    color: #1B2559;
    opacity: 0.6;
    margin-left: 27.5px;
}

.buttonLIT{
 margin-top: 21.5rem;
 background-color: white;
 border-radius: 2rem;
 height: 46px;
 font-family: 'DM Sans', sans-serif;
 margin-left: 1.25rem;
 width: 18rem;
 color: #47548C;
 cursor: pointer;


background: #FFFFFF;
/* Secondary/Grey/600 */
border: 1px solid #A3AED0;
box-sizing: border-box;
border-radius: 70px;
}

.buttonIS{
    margin-top: 21.5rem;
 background-color: #11047A;
 border-radius: 2rem;
 height: 46px;
 font-family: 'DM Sans', sans-serif;
 margin-left: 1.25rem;
 width: 18rem;
 cursor: pointer;
 color: white;
}
.buttonLink{
    color: #47548C;
}