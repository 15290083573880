.report_card {
  width: 100%;
  outline-color: black;
  border-radius: 1.2rem;
  height: 100%;
  outline-width: 5px;
  margin-top: 2rem;
  border-color: #e5e5e5;
  border-width: 10px;
  padding-top: 10px;
  
}

.favicon {
  height: 24px;
  width: 24px;
  margin: 11px;
  align-self: center;
}

.fav_cont {
  height: 100%;
  width: 100%;
  border-radius: 10px;

  background-color: #f4f7fe;
}

.innerRC {
  position: static;
  /* height: 190px; */
  left: 0px;
  right: 0px;
  align-items: flex-start;
  margin-bottom: 10px;
  /* Secondary/ Primary White */
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
  background: #ffffff;
  /* Secondary/Grey/400 */
  background-color: #f4f7fe;

  border: 1px solid #bccfff;
  box-sizing: border-box;
  border-radius: 16px;
}

.siteUrl {
  color: #707eae;
  font-size: 14px;
  margin-left: 82.16px;
}

.siteName {
  margin-left: 82.16px;
  font-size: 16px;
}

.site {
  padding-top: 1.25rem;
  padding-left: 1rem;
  margin-bottom: 22px;
}

.button1-group {
  width: 10rem;
  height: 46px;
  border-radius: 70px;
  background-color: #11047a;
  color: white;
  border-color: #11047a;
  border-style: solid;
  font-family: "DM Sans";
  margin-top: 1.35rem;
  cursor: pointer;
  margin-right: 16px;
}
.button2-group {
  width: 10rem;
  height: 46px;
  border-radius: 70px;
  border-color: #11047a;
  border-style: solid;
  font-family: "DM Sans";
  margin-top: 1.35rem;
  cursor: pointer;
  margin-right: 16px;
}

.button-item {
  display: flex;
  align-items: left;
  justify-content: space-between;
}

.history_title {
  color: #707eae;
  font-size: 14px;
  margin-left: 31.58px;
  margin-right: 5px;
}

.history_details {
  display: inline-flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 4px;
}

.history_last {
  color: black;
  font-size: 16px;
}

.history_level {
  color: #707eae;
  font-size: 16px;
}

.history_issue {
  color: red;
  font-size: 16px;
}

.button_nav {
  color: white;
}
