.security {
  margin-left: 290px;
}
.heading {
  padding: 30px;
}
.accessEmail {
  /* Basic Info */
  z-index: 1;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  /* Secondary/Grey/900 */

  color: #2b3674;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.resetpassword {
  background: #ffffff;
  /* Secondary/Grey/600 */

  border: 1px solid #a3aed0;
}
.accessEmaiList{
  display: contents;
}
.emails {
  padding: 10px 10px;
  margin-top: 10px;
  width: 440px;
  /* height: 46px; */
  background-color: #f4f7fe;
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;
  color: #707eae;
  border-radius: 10px;
}
.deatils {
  /* X-Large_Product Purple */

  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;

  width: 516px;
  /* height: 470px; */

  /* Secondary/ Primary White */

  background: #ffffff;
  /* Shadows/Soft Shadow/Soft Shadow - Style 1 */

  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.12);
  border-radius: 20px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.adminDiv {
  font-weight: 200;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 40px; */
  padding: 40px;
  gap: 24px;
  position: absolute;
  width: 800px;
  height: 654px;
  left: 320px;
  top: 116px;
  background: #ffffff;
  border-radius: 10px;
}
