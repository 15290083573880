.PromoreButton{
    height: 46px;
width: 40px;
margin-right: 6px;
top: 0px;
border-radius: 70px;
background: #FFFFFF;
/* Secondary/Grey/600 */

/* border: 1px solid #A3AED0; */
border: none;
box-sizing: border-box;
/* border-radius: 70px; */
}
.members-main-membersList-table {
    height: fit-content;
    /* max-height: 0px; */
    overflow-y: scroll;
}
.members-table-body{
    display: block;
    max-height: 200px;
    overflow-y: scroll;
    width: 100%;
}